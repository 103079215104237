.modal {
  text-align: center;
  padding: 0;
}
.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}
.modal-dialog {
  max-width: none;
  width: 50vw;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.refill-buckets-modal div tr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#refillDone {
  width: 5%;
}

#bucketPosition {
  width: 5%;
}

#bucketDrug {
  text-align: left;
  width: 30%;
}
#bucketDIN {
  width: 15%;
}

#expirationDate {
  width: 10%;
}

#refillBtns {
  width: fit-content;
  margin-right: 3%;
}

#refillBtns div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#refillBtns #removeBucketFromList {
  margin-left: 15px;
}

.upper-row,
.lower-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.lower-row > div.form-group {
  width: 50%;
}

.upper-row .upc-field {
  width: 65%;
}

.upper-row .qty-field {
  width: 35%;
}

.input-section {
  width: 85%;
}

div.row.form-group {
  gap: 10px;
}

input.form-control {
  font-size: 0.9rem;
}

.modal-header .modal-title {
  width: 100%;
}
.single-refill-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.single-refill-text {
  display: block;
}
.single-refill-title Button {
  display: block;
  height: 50%;
}

td#refillBtns div button.btn.btn-primary {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

td#refillBtns div button.btn.btn-primary svg {
  margin-right: 10px;
}
