.force-field-row {
  display: flex;
  flex-direction: row;
}

.force-field-input {
  width: 60%;
}

.force-field-row input {
  width: 100%;
}

.force-field-row button,
.force-field-row select {
  margin-left: 3%;
}

.force-field-row button {
  height: fit-content;
}

.force-field-unit {
  display: flex;
  flex-direction: row;
  width: 40%;
}

.force-field-unit select {
  width: 70%;
}

.toast-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  align-content: center;
  gap: 5px;
}

.DIN-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
