.TrayIconContainer svg {
  display: block;
  width: 24px;
  height: 15px;
}

.singleTrayOptions {
  margin-right: 3%;
}

.singleTrayOptions .btn-group-toggle {
  height: 100%;
}

.singleTrayOptions .btn-group-toggle .btn.btn-primary {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.singleTrayOptions .btn-primary {
  background-color: white;
}

.singleTrayOptions .btn-primary:not(:disabled):not(.disabled).active {
  border-color: #007bff;
  background-color: #007bff;
}

.tray-row {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.tray-id {
  margin-left: 2%;
  width: 15%;
}

.tray-edit-buttons {
  display: flex;
  flex-direction: row;
}

.tray-edit-buttons button {
  margin-right: 5px;
}

.edit-form-row {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.edit-form-row .form-group {
  width: 50%;
  margin-right: 3%;
}

.edit-form-row Button {
  margin-right: 5px;
}

.error-message {
  color: red;
}
