.barcode-group .form-group {
  display: flex;
  flex-direction: column;
}

.barcode-group .form-group div {
  display: flex;
  flex-direction: row;
}

.barcode-group .form-group div button {
  margin-left: 3%;
}

.cubi-import-error {
  margin-top: 3%;
}
