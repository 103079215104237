table td,
table th {
  padding: 0.5rem;
  text-align: left;
  vertical-align: middle !important;
}

td.actions {
  white-space: nowrap;

  .dropdown {
    display: inline-block;
  }
}

tr.deactivated td :not(.no-strike) {
  text-decoration: line-through;
}

div.no-strike.dropdown button.btn {
  text-decoration: none;
}

div.no-strike.dropdown div.dropdown-menu {
  text-decoration: none;
}

div.no-strike.dropdown div.dropdown-menu a.dropdown-item {
  text-decoration: none;
}

.table-striped tbody tr.lowMonthlyQty,
.table-striped tbody tr.lowQuantity {
  background-color: #fff3cd;
}

div.ifExpiringSoon div.btn-group input {
  display: none;
}

.table-striped tbody tr.isExpired {
  background-color: #f1948a;
}

.table-striped tbody tr.isExpiringSoon {
  background-color: #f1948a;
}

div.ifExpiringSoon div.btn-group {
  width: 80%;
}

div.ifExpiringSoon div.btn-group label {
  width: 50%;
}

.upc-field {
  width: 33%;
  padding-left: 15px;
}

.qty-field {
  width: 18%;
  padding-left: 15px;
}

.lotnum-field {
  width: 18%;
  padding-left: 15px;
}

.exp-field {
  width: 19%;
  padding-left: 15px;
}

.remove-button {
  width: 5%;
  margin-left: 7%;
  height: fit-content;
  padding: 1%;
}

.editQuantityModal {
  width: 60%;
}

.bucket-list-modal.modal {
  text-align: center;
  padding: 0;
}
.bucket-list-modal.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}
.bucket-list-modal.modal-dialog {
  max-width: none;
  width: 50vw;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.bucket-list-modal .form-body {
  display: flex;
  flex-direction: row;
  padding-left: 15%;
}

.bucket-list-modal .form-group {
  display: flex;
  flex-direction: column;
  margin: 0 3%;
}

.bucket-slist-modal input,
.bucket-list-modal select {
  height: 55%;
  border-radius: 0.25rem;
}

.custom-select {
  height: 2.25em;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  color: #495057;
}

.bucket-list-modal .error-select,
.error-file {
  border-color: red;
}

.bucket-list-modal .error-message {
  color: red;
  font-weight: normal;
  font-size: small;
}

.bucket-org {
  font-size: smaller;
}

.bucket-position {
  min-width: fit-content;
  text-wrap: nowrap;
}

.deactivate-bucket-modal select {
  margin-left: 3%;
}

.bucket-name-cell {
  display: flex;
  flex-direction: column;
  text-wrap: nowrap;
}

.bucket-name-cell button {
  align-self: baseline;
}

.bucket-name-cell .deactivated-reason {
  display: flex;
  flex-direction: row;
  font-size: smaller;
  align-items: center;
  padding-left: 0.75rem;
}

.exclusions-alert-message {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.exclusions-alert-message button {
  color: #856404;
}

.exclusions-alert-message button:hover {
  color: #856404;
}

.exclusions-alert-message button:focus {
  box-shadow: 0 0 0 0.2rem rgba(133, 100, 4, 0.25);
}

#DeactivateBucket_form div.form-group {
  display: flex;
  align-items: baseline;
}

.file-changes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  padding: 10px 20px;
  background-color: rgb(248, 248, 248);
}

.sorting {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.bucket-badges {
  margin-top: 10px;
}

.unload-multiple-table {
  max-width: 100%;
  max-height: 650px;
  overflow-y: auto;
  overflow-x: auto;
  margin-top: 15px;
  margin-bottom: 20px;
}
