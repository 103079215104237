.dashboard {
  margin-left: 40px;
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(5, minmax(280px, 12vw));
  grid-template-rows: minmax(122px, 10vh) minmax(306px, 25vh) minmax(306px, 25vh);
  gap: 50px;
  width: 100vw;
  height: 100vh;
}

.included-exluded-container {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 2;
  grid-row-end: 3;
}

.componentBase {
  padding: 10px;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.patient-day-container {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 4;
  padding: 30px;
}

.mean-speed-container {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 30px;
  min-width: 12vw;
}

.info-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 14px;
  color: #555;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #000;
  }
}

.mean-duration-container {
  position: relative;
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 2;
}

.mean-duration-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  height: 38px;
}

.mean-duration-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
}

.mean-duration-value {
  display: flex;
  align-content: center;
  font-weight: 600;
  align-items: center;
  margin-top: 10px;
}

.total-mean-container {
  position: relative;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  padding: 30px;
}

.mean-container {
  position: relative;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  padding: 30px;
}

.meanByMonth-container {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  padding: 30px;
}

.daily-unload-container {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 3;
}

.daily-unload-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin: 8px;
}

.daily-unload-header {
  display: flex;
  flex-direction: column;
}

.buckets-not-used-container {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.buckets-not-used-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin: 8px;
}

.buckets-not-used-table {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #0071e9;

  .td {
    padding: 0px;
  }
}

.scrollable-container {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #e9e9e9;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #c0c0c0;
  }
}

.nb-deactivated-bucket-container {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nb-deactivated-bucket-total {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  color: #646464;
}

.mean-speed-icon {
  font-size: 28px;
  display: flex;
  color: #0071e9;
  justify-content: center;
  align-items: center;
  background-color: #0071e91c;
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

.total-drug-icon {
  font-size: 14px;
  display: flex;
  color: #f08234;
  justify-content: center;
  align-items: center;
  background-color: #f0823442;
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

.mean-drug-icon {
  font-size: 14px;
  display: flex;
  color: #8727e0;
  justify-content: center;
  align-items: center;
  background-color: #8727e044;
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

.time-sections {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 12px;
}

.time-placeholder {
  display: flex;
  justify-content: left;
  gap: 8px;
  font-size: 24px;
  font-weight: 700;
}

.total-mean-placeholder {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: left;
  width: 100%;
  height: 100%;
  font-size: 24px;
  font-weight: 750;
}

.total-value {
  font-size: 32px;
  font-weight: 700;
}

.included-excluded-icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  gap: 2px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  font-weight: 700;
  font-size: 15px;
  transform: translateY(-200%) translateX(130%);
}

@media screen and (max-width: 1900px) {
  .included-excluded-icon {
    transform: translateY(-190%) translateX(115%);
  }
}

.error-message-dashboard {
  color: 'red';
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  text-align: center;
}

.custom-tooltip {
  background-color: #000000;
  color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 5px #0000001a;
  font-size: 14px;
  padding: 0 10px;
  font-weight: 700;
  text-align: center;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72%;
  width: 100%;
  font-size: 14px;
  color: #b4b4b4;
  font-style: italic;
}
