.pro-sidebar {
  color: #adadad;
  height: 100%;
  width: 240px;
  min-width: 240px;
  text-align: left;
  transition: width, left, right, 0.3s;
  position: fixed;
  top: 0;
  z-index: 1009;
}

.pro-sidebar > .pro-sidebar-inner,
.pro-inner-list-item {
  background-color: #7e7e7e;
  color: rgb(255, 255, 255);
  font-size: 1.2em;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

.pro-sidebar .pro-menu .pro-menu-item {
  font-size: 18px;
}
