.center {
  min-width: 19%;
  min-height: 40vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  padding: 30px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

form {
  width: 100%;
}

#Login_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login_title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 4vh;
  text-align: center;
}

.form-group {
  width: 100%;
  text-align: left;
  font-weight: bold;
}

.connexion {
  width: 100%;
  margin-top: 20px;
  background-color: #0171c1;
}

.connexion:hover {
  background-color: #097ed1;
}

.logoChrono {
  margin-bottom: 5vh;
  margin-top: 3vh;
}

.logoChronoImage {
  width: clamp(150px, 15vw, 300px);
  height: auto;
}

.twoFAmodal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 30px;
}

.twoFAmodal-check {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.two-fa-modal {
  .modal-dialog {
    max-width: 450px;
  }
}

.error-twofamodal {
  color: red;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
}

.error-message {
  color: #dc3545;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  font-weight: bold;
}
