.container {
  top: 30%;
  left: 10px;
  text-align: center;
}

.container h2 {
  font-weight: bold;
}

.container a {
  font-size: 1.4rem;
}

div[role='button'] {
  outline: none;
}

.table-responsive {
  overflow-x: visible;
}

h2 {
  text-align: left;
}

.toast-alert {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1060;
}
