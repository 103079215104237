.error-page {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  text-align: center;
}

.error-icon svg {
  font-size: 15rem;
}

.error-title {
  font-size: larger;
  font-weight: bolder;
}

.error-page Button {
  margin-top: 3%;
}

.maintenance .error-icon {
  padding-bottom: 5%;
}

.maintenance .error-title {
  font-size: xx-large;
}

.maintenance .error-code {
  font-size: larger;
  padding-bottom: 5%;
}

.maintenance button {
  width: 30%;
  align-self: center;
}
