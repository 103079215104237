.noQty {
  background-color: white;
  color: grey;
  border: solid 1px grey;
}

.noCard {
  background-color: white;
  color: white;
  border: solid 1px grey;
}

.quarter {
  background-color: #d3ecec;
  color: #36b8c5;
  border: solid 1px #36b8c5;
}

.half {
  background-color: #ffe7d7;
  color: #e2753b;
  border: solid 1px #e2753b;
}

.three-quarter {
  background-color: #ede3ed;
  color: #7047ce;
  border: solid 1px #7047ce;
}

.other {
  background-color: #c1e7d9;
  color: #009933;
  border: solid 1px #009933;
}

.x-distribute {
  background-color: #fff7cc;
  color: #f4b400;
  border: solid 1px #f4b400;
}

.whole {
  background-color: #fcd6de;
  color: #eb328b;
  border: solid 1px #eb328b;
}

.full-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
  border: 2px solid #adadad;
  border-radius: 15px;
  padding: 2%;
}

.blister-card-container {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.blister-cards {
  padding-top: 3%;
  padding-bottom: 1%;
  position: relative;
  transition: top 0.15s ease-in-out;
  display: flex;
  flex-direction: column;
}

.full-card {
  width: 100%;
}

.full-card:nth-child(2) {
  margin-top: 1%;
}

.left-card,
.right-card {
  width: 50%;
}

.right-card .blister-card-row {
  justify-content: flex-end;
}

.blister-card-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.blister-card-id {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.blister-card-row span {
  display: flex;
  width: 21.5%;
  // padding: 3% 0;
  border-radius: 5px;
  margin: 3px;
  justify-content: center;
}

.complete-distribution {
  align-self: center;
  margin-top: 5px;
}

.skipIcon {
  color: #f40000;
  cursor: pointer;
}

.skipIcon:hover {
  color: #ff4f4f;
}
