.already-linked-alert {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: normal;
}

.already-linked-alert Button {
  height: 50%;
}
.already-linked-text {
  display: flex;
  flex-direction: column;
}

.already-linked-text span {
  font-weight: bold;
}

.buttonLayout {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
