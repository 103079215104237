.expanded-order {
  display: flex;
  flex-direction: row;
}

.drugs-lists {
  width: 50%;
  padding-left: 15px;
}

.excluded-list,
.included-list {
  width: 80%;
}

.title-Row .drugName-cell,
.title-Row .drugName-cell {
  padding-left: 12;
}

.drugTimeTitle,
.drugTime {
  width: 5%;
  text-align: center;
}

.title-AM,
.drug-0 {
  margin-left: 3px;
}

.titleMatrix-cell {
  font-weight: bold;
}

.matrix-and-upc-form {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 3%;
}

.matrix-distribution {
  width: 30%;
}
.matrix-distribution div {
  display: flex;
  flex-direction: row;
}

.matrix-distribution span {
  width: 25%;
}

.matrix-cell .matrix-distribution {
  width: 100%;
}

.matrix-cell .matrix-distribution div span {
  width: 25%;
  min-width: fit-content;
  margin: 2px;
}

.matrix-cell .matrix-distribution .titleMatrix-cell {
  font-size: small;
}

.unknown-drug .drugName-cell {
  width: 30%;
  margin-right: 3%;
}

.unknown-drug .matrix-cell {
  width: 20%;
}

.flag-reason {
  font-size: small;
}

.included-row .btn:hover svg path,
.excluded-row .btn-include-drug:hover svg path {
  fill: #fff;
}
