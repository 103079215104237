.TrayIconContainer svg {
  display: block;
  width: 24px;
  height: 15px;
}

.cardsOptions .btn-primary {
  background-color: white;
}
.cardsOptions .btn.btn-primary.active {
  border-color: #007bff;
  background-color: #007bff;
}

.noClick {
  pointer-events: none;
}
.noClick.cardsOptions .btn.btn-primary.active {
  border-color: grey;
  background-color: grey;
}

.noClick .btn-primary {
  border-color: grey;
}
