.organization-form-modal .error-text,
.organization-user-form .error-text {
  border-color: red;
}

.collapse-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 1vh;
  margin-bottom: 2vh;
}

.collapse-form {
  background-color: #dee2e6;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 2vh;
}

.collapse-submit-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 1vh;
}

.collapse-submit-button {
  float: right;
}

/*** OrganizationRow.tsx ***/

.organizationRow .tableCell--narrow {
  width: 5%;
  text-align: right;
}

.organizationRow .tableCell--expand {
  width: auto;
}

.organization-details-modal {
  width: 75%;
  max-width: 100%;
}

.in-table-form-input {
  margin-bottom: 0;
}

.prescriptionSoftwareForm {
  display: flex;
  flex-direction: row;
}

.prescriptionSoftwareForm .form-group {
  display: flex;
  flex-direction: column;
  margin-right: 1.5%;
}

.custom-select {
  height: 2.25em;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  color: #495057;
}

.organization-status-cell {
  width: 3%;
  text-align: center;
}

.organization-status {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 50%;
}

.organization-status select {
  margin-left: 3%;
}
