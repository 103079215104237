@import '../../styles/_variables';

.top-menu {
  visibility: visible;
  height: 2.5rem;
  padding: 0.5rem 0;

  @media screen and (min-width: ($sidebar-breakpoint + 1)) {
    visibility: hidden;
  }

  button {
    border: none;
    background: #ccc;
    border-radius: 4rem;
    padding: 0.25rem;
    width: 2rem;
    height: 2rem;
  }
}
