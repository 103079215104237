.organization-view {
  margin-right: 15px;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  height: fit-content;
}

.buckets-btns .btn button {
  vertical-align: top;
}

.buckets-btns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-right: 5px;
  gap: 15px;
}

.dashboard-title {
  display: flex;
  flex-direction: row;
}

.pdf-viewer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 4vh;
}

.react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-pdf__Page {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  margin-bottom: 10px;
}

.react-pdf__Page__textContent {
  display: none; /* Hide raw text layer */
}

.pdf-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 2vh;
}
