div.altChoices div.btn-group input {
  display: none;
}

div.altChoices div.btn-group {
  width: 80%;
}

div.altChoices div.btn-group label {
  width: 50%;
}

.modal-backdrop.show {
  opacity: 0.1;
}

.alt-informations {
  font-weight: bold;
}
