.actions-tab {
  display: flex;
  flex-direction: row;
  width: 60%;
}

.actions-buttons {
  width: 30%;
}

.actions-buttons button {
  margin-bottom: 10px;
  width: 80%;
}

.actions-ui {
  width: 70%;
}

.upc-to-validate {
  display: flex;
  flex-direction: row;
  padding-left: 5%;
}

.upc-to-validate span {
  display: block;
  width: 50%;
}

.upc-to-validate button {
  margin-left: 3px;
}

.delayArchive {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
