.neutral,
.valid,
.invalid {
  width: 35%;
}

.noClick {
  pointer-events: none;
}

.excluded-row {
  border-radius: 8px;
}

.neutral {
  padding: 3px;
  background-color: lightgray;
  border-color: solid 1px grey;
}

.valid {
  padding: 3px;
  background-color: #d4edda;
  border: solid 1px #28a745;
}

.warning {
  padding: 3px;
  background-color: #fff3cd;
  border: solid 1px #ffc107;
}

.invalid {
  padding: 3px;
  background-color: red;
  border: solid 1px darkred;
}

.scanUPC {
  border: none;
  border-radius: 0 0 8px 8px;
}

.excluded-row,
.included-row,
.title-Row {
  width: 100%;
  margin-bottom: 2px;
}

.included-row,
.title-Row {
  display: flex;
  flex-direction: row;
}

.included-row {
  align-items: baseline;
}
.title-Row {
  font-weight: bold;
}

.title-Row .drugName-cell {
  padding-left: 12px;
}

.excluded-row .drugName-cell,
.included-row .drugName-cell {
  padding: 0.375rem 0.75rem;
}

.upc-and-refresh {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding-left: 5%;
}

.upc-and-refresh button {
  margin-left: 3%;
}

div.upc-and-refresh div div.invalid-feedback {
  color: black;
}

.drug-UPC-and-refresh {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.excluded-row div.drug-info {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.drugName-cell {
  width: 50%;
  min-width: fit-content;
  display: flex;
  flex-direction: column;
}

.drugName-cell button {
  text-align: left;
  padding-bottom: 0;
  margin-bottom: 0;
}

.drugName-cell div {
  padding-left: 0.75rem;
}

.drugName-cell span {
  padding-left: 10%;
  font-size: small;
}

.drugForce-cell {
  width: 10%;
  min-width: fit-content;
  text-align: left;
}

.split-cell,
.completed-cell {
  width: 4%;
  text-align: right;
}

.flag-cell {
  width: 10%;
  text-align: center;
}

.action-cell {
  min-width: fit-content;
}

.action-cell button {
  width: 100%;
}

.scanUPC {
  width: 70%;
}

.scanUPC label {
  margin-left: 5%;
}

.closeActive-cell {
  width: 14%;
  text-align: end;
  padding-right: 5px;
}

.unknown-drug .drugName-cell {
  width: 30%;
  margin-right: 3%;
}

.unknown-drug .matrix-cell {
  width: 20%;
}

.flag-reason {
  font-size: small;
}

.included-buttons-cell {
  display: flex;
  flex-direction: row;
  margin-left: 3%;
}

.included-buttons-cell button {
  margin-left: 8%;
}
