.rdt_Table {
  .btn {
    white-space: nowrap;
  }

  .timestamps {
    white-space: nowrap;
  }

  input[type='checkbox'] {
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: middle;
    cursor: pointer;
  }

  .progress {
    flex: 1;
    margin-left: 1rem;
  }

  .rdt_TableRow {
    font-size: 1em;
  }
}

.pendingIcon {
  animation: spin normal 1.5s infinite ease-in-out;
}

.trayInProcessIcon {
  position: relative;
  display: inline-block;
  animation: trayAnimation 4s infinite;
}

@keyframes trayAnimation {
  0% {
    color: #007bff;
  }
  50% {
    color: #adadad;
  }
  100% {
    color: #007bff;
  }
}

.table-not-striped tbody tr:nth-of-type(odd) {
  background-color: transparent !important;
}

.error-modal .modal-body {
  white-space: pre-wrap;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.rdt_Table .rdt_TableRow {
  border-bottom: 0px !important;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}

.rdt_ExpanderRow {
  margin-bottom: 0.8%;
}

div.toggle-btn div.btn-group input {
  display: none;
}

div.toggle-btn div.btn-group {
  width: 80%;
}

div.toggle-btn div.btn-group label {
  width: 50%;
}
.small-notice {
  font-size: smaller;
  margin-top: 1em;
}

#checkedUnlinkFromTray {
  width: 1.5rem;
  height: 1.5rem;
}

.validation-association-older-order {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.order-filters {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.order-filters .search-bar {
  width: 80%;
  position: relative;
  display: flex;
}

.order-filters .organization-view {
  width: 20%;
  display: block;
}

div.search-bar div.form-control-and-error {
  display: flex;
  flex-direction: column;
  width: 100%;
}
