.password-reset-modal {
  width: 50%;
}

.userrow-toastalert {
  position: fixed;
  word-wrap: break-word;
  color: white;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
}

.userrow-toastalert p {
  margin: 0;
}
