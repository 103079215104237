.new-exclusions-table {
  width: 100%;
}

.new-exclusions-table th {
  padding: 0 15px;
  text-align: center;
}

table.new-exclusions-table tr td {
  text-align: center;
}

.new-exclusions-table div.full-card {
  background-color: #fff;
}
