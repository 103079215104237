.buttons-container {
  display: block;
  width: 100%;
}

.order-buttons,
.secondary-order-buttons {
  display: flex;
  flex-direction: row;
}

.main-order-buttons {
  display: block;
  width: 50%;
  min-width: fit-content;
}

.secondary-order-buttons button {
  margin-left: 5px;
}

.order-buttons .main-order-buttons button {
  display: block;
  width: 100%;
}

.main-order-buttons div.popover {
  padding: 10px;
  color: #856404;
  border: 2px solid #ffeeba;
  background-color: #fff3cd;
}
